import supabase from "@/utils/supabase";
import Button from "@/components/Button.vue";

export default {
  name: "Login",
  components: {
    Button,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      const { data, error } = await supabase.auth.signIn({
        email: this.email,
        password: this.password,
      });
      if (error) {
        this.$toasted.global.mwError({ message: error.message });
      } else {
        console.log(data);
        this.$router.push({ path: "/app" });
      }
    },
  },
};
